<template>

  <BlockUI :blocked="loading>1">

    <div class="p-grid">

      <div class="p-col-12 p-md-6">
        <h3><i class="pi pi-comments" style="font-size: 1.6rem"></i> Tickets</h3>
      </div>

    </div>

    <div class="p-grid">

        <div class="p-col-12">
          <AutoComplete v-model="filtrosSelecionados" :multiple="true" :suggestions="filtrosResultados" @complete="filtrarAutoComplete($event)" 
          field="completeLabel" optionGroupLabel="label" :completeOnFocus="true" optionGroupChildren="items" placeholder="Filtre seus resultados" style="display: block">
            <template #item="slotProps">
              <div>{{slotProps.item.label}}</div>
            </template>
          </AutoComplete>
        </div>



        <div class="p-col-12">

            <Accordion class="p-mb-3">
                <AccordionTab>
                    <template #header>
                        <span class="p-mr-2">Novo Ticket</span>
                        <i class="pi pi-plus"></i>
                    </template>

                    <div class="p-grid p-fluid">

                        <div class="p-col-12 p-md-6 p-field" v-if="corretora">
                          <label for="destinatario">Destinatário</label>
                          <div class="p-inputgroup">
                            <AutoComplete
                              id="destinatario"
                              v-model="destinatario"
                              :suggestions="destinatarioResultados"
                              @complete="destinatarioFiltrarAutoComplete($event)"
                              @blur="validaAutoComplete('destinatario')"
                              field="label"
                              optionGroupLabel="label"
                              optionGroupChildren="items"
                              placeholder="Informe parte do nome"
                              :class="
                                fieldsError.some((v) => v === 'destinatario') ? 'p-invalid' : ''
                              "
                            />
                          </div>
                        </div>

                        <div class="p-col-12 p-md-6 p-field">
                          <label for="processo">Análise Relacionada (opcional)</label>
                          <div class="p-inputgroup">
                            <AutoComplete
                              id="processo"
                              v-model="processo"
                              :suggestions="processoResultados"
                              @complete="processoFiltrarAutoComplete($event)"
                              @blur="validaAutoComplete('processo')"
                              field="label"
                              placeholder="Informe o número do negócio"
                              :class="
                                fieldsError.some((v) => v === 'processo') ? 'p-invalid' : ''
                              "
                            />
                            <Button
                              label="acessar"
                              class="p-button-warning"
                              v-if="processo"
                              @click="abrirProcesso"
                            />
                          </div>
                        </div>

                        <div class="p-field p-col-12">
                          <label for="assunto">Assunto</label>
                          <InputText
                          id="assunto"
                          type="text"
                          v-model="assunto"
                          :class="(fieldsError.some(v => v === 'assunto'))?'p-invalid':''"
                          maxlength="100"
                          />
                        </div>

                        <div class="p-field p-col-12">
                          <label for="mensagem">Mensagem</label>
                          <Textarea 
                          :autoResize="true"
                          id="mensagem"
                          v-model="mensagem"
                          :class="(fieldsError.some(v => v === 'mensagem'))?'p-invalid':''"
                          rows="5" cols="30"
                          />
                        </div>

                        <div class="p-field p-col-12" v-if="corretora">
                          <label for="mensagem_corretora">Mensagem Interna (Corretora)</label>
                          <Textarea 
                          :autoResize="true"
                          id="mensagem_corretora"
                          v-model="mensagem_corretora"
                          :class="(fieldsError.some(v => v === 'mensagem_corretora'))?'p-invalid':''"
                          rows="1" cols="30"
                          />
                        </div>                        

                        <div class="p-col-12">
                            <FileUpload 
                                chooseLabel="Anexar Arquivos" 
                                mode="basic" 
                                name="upload[]" 
                                :url="$api.url() + '/arquivos'"
                                :auto="true" 
                                :multiple="true"
                                :maxFileSize="50000000"
                                accept="image/*,video/*,application/pdf,application/zip"
                                @before-send="upload_headers"
                                @upload="upload_finalizado"
                                @progress="upload_progress"
                                @error="upload_error"
                            >
                            </FileUpload>
                        </div>
                        <div class="p-col-12" v-if="progress_upload > 0 && progress_upload < 100">
                            <ProgressBar :value="progress_upload"></ProgressBar>
                        </div>
                        <div class="p-col-12" v-if="arquivos.length > 0">
                            <span v-for="a of arquivos" :key=a.id>
                                <Button :label="a.filename_original" class="p-mr-2 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="upload_click($event, a)"></Button>
                            </span>
                        </div>
                        <div class="p-col-12">
                            <Button label="Criar Ticket" class="p-button-lg p-button-success" style="width: auto" icon="pi pi-check" @click="criar"></Button>
                        </div>
                    </div>

                </AccordionTab>
            </Accordion>

            <div class="card p-fluid">

              <DataTable sortMode="single" sortField="atual_interacao_data" :sortOrder="-1" :value="tickets" :loading="loading>0" selectionMode="single" v-model:selection="ticket">
                <Column field="id" header="#" class="col-id"></Column>
                <Column field="criador_item.name" header="Criador" class="col-criador">
                  <template #body="slotProps">
                    {{ slotProps.data.criador_item.name }} ({{ (!slotProps.data.criador_item.imobiliaria_item)?'Corretora':slotProps.data.criador_item.imobiliaria_item.nome }})
                  </template>
                </Column>
                <Column header="Aguardando" class="col-aguardando">
                  <template #body="slotProps">
                    <div v-if="slotProps.data.atual_status_destinatario == 'CONCLUIDO'">
                      -
                    </div>
                    <div v-else-if="slotProps.data.atual_destinatario">
                    {{ slotProps.data.atual_destinatario_item.name }} ({{ (!slotProps.data.atual_destinatario_item.imobiliaria_item)?'Corretora':slotProps.data.atual_destinatario_item.imobiliaria_item.nome }})
                    </div>
                    <div v-else-if="slotProps.data.atual_destinatario_imobiliaria">
                    {{ slotProps.data.atual_destinatario_imobiliaria_item.nome }} (Imobiliária)
                    </div>
                    <div v-else>
                      Corretora
                    </div>
                  </template>
                </Column>
                <Column header="Negócio / Locatário" class="col-negocio">
                  <template #body="slotProps">
                    <div v-if="slotProps.data.processo != null">
                      {{ '#' + slotProps.data.processo_item.id + ' (' + slotProps.data.processo_item.locatario_item.nome + ')' }}
                    </div>
                    <div v-else>
                      -
                    </div>
                  </template>
                </Column>
                <Column :sortable="true" field="atual_interacao_data" header="Última Interação" class="col-ultima_interacao">
                  <template #body="slotProps">
                    {{ $utils.formatDateTimeToBr(slotProps.data.atual_interacao_data) }}
                  </template>
                </Column>
                <Column field="assunto" header="Assunto" class="col-assunto">
                  <template #body="slotProps">
                    {{ slotProps.data.assunto }}
                  </template>                
                </Column>
                <Column :sortable="true" field="status" header="Status" class="col-status">
                  <template #body="slotProps">
                    <Tag 
                      v-if="$auth.currentUser.id == slotProps.data.atual_remetente"
                      :value="status[slotProps.data.atual_status_remetente].label"
                      :icon="status[slotProps.data.atual_status_remetente].icon" 
                      :severity="status[slotProps.data.atual_status_remetente].severity">
                    </Tag>
                    <Tag 
                      v-else
                      :value="status[slotProps.data.atual_status_destinatario].label"
                      :icon="status[slotProps.data.atual_status_destinatario].icon" 
                      :severity="status[slotProps.data.atual_status_destinatario].severity">
                    </Tag>
                  </template>
                </Column>
                <Column class="col-actions">
                  <template #body="slotProps">
                      <Button icon="pi pi-trash" v-tooltip.left="'Arquivar Ticket'" class="p-button-rounded p-button-danger" @click="arquivar($event, slotProps.data.id)" />
                  </template>
                </Column>
                <template #empty>
                    Nenhum Ticket encontrado.
                </template>
              </DataTable>

            </div>
            
        </div>

    </div>

    <div class="center-loading" v-if="loading>1">
        <ProgressSpinner />
    </div>

  </BlockUI>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
</template>

<script>

export default {
  data() {
    return {

      // AUTOCOMPLETE OPCIONAL: //
      filtrosPersonalizados: 'Número/Assunto',
      // AUTOCOMPLETE OBRIGATORIOS: //
      filtrosSelecionados: null,
      filtrosResultados: [],
      filtrosOpcoes: [],
      filtrosQuery: "",
      // FIM AUTOCOMPLETE //

      // AUTOCOMPLETE OBRIGATORIOS: //
      processo: null,
      processoResultados: [],

      destinatario: null,
      destinatarioResultados: [],
      // FIM AUTOCOMPLETE //

      api_get: '?fields=id,data,atual_interacao_data,assunto,atual_status_destinatario,atual_status_remetente,processo&relationships=criador_item:name;atual_destinatario_item:name;atual_destinatario_imobiliaria_item:nome;processo_item:id',

      // 1. Controle de apresentação
      loading: 0,
      progress_upload: 0,    

      // 1.1. Validadores
      fieldsError: [],

      // 2. Campos do Formulário
      arquivos : [],
      assunto: "",
      mensagem: "",
      mensagem_corretora: "",

      // 3. Listagem de Tickets
      tickets: [],

      // 4. Chaves Estrangeiras
      status: {
        'NOVO' : {
          label: 'NOVO',
          severity: "info",
          icon: "pi pi-clock",
        },
        'RESPONDIDO' : {
          label: 'RESPONDIDO',
          severity: "warning",
          icon: "pi pi-clock",
        },
        'AGUARDANDO' : {
          label: 'AGUARDANDO',
          severity: "warning",
          icon: "pi pi-clock",
        },
        'CONCLUIDO': {
          label: 'CONCLUÍDO',
          severity: "success",
          icon: "pi pi-check",
        },
        'ARQUIVADO': {
          label: 'ARQUIVADO',
          severity: "danger",
          icon: "pi pi-times",
        },
      },

      corretora: false,
      ticket: null,

    };
  },

  mounted() {
    
    const self = this;

    if(this.$auth.currentUser.imobiliaria == null)
      this.corretora = true;

    self.loading += 2;

    this.$api.get('/tickets/filtros').then(function (response) {

      const dados = response.data;

      if(dados.success) {

        self.filtrosOpcoes = dados.data;
 
      }

      self.loading--;
      
    });

    this.$api.get('/tickets' + this.api_get).then(function (response) {

      const dados = response.data;

      if(dados.success) {
        self.tickets = dados.data;
      }

      self.loading--;
      
    });


  },

  watch: {

    filtrosSelecionados(v) {

      const self = this;

      if(Array.isArray(v) && v.length > 0) {
        var p = {};
        this.filtrosSelecionados.forEach((f) => {
          if(typeof p[f.group] == "undefined")
            p[f.group] = [];
          p[f.group].push(f.id);
        });
        self.filtrosQuery = JSON.stringify(p); //TODO PASSAR PARA A URL PARA QUE QUANDO A PESSOA ATUALIZAR A TELA OS FILTROS PERMANEÇAM
      }

      this.carregarDadosFiltrados();

    },

    ticket: function(val) {
      if(val != null) {
        this.$router.push({ path: `/ticket/${val.id}` });
      }
    },

  },

  methods: {

    // // // Métodos padrões para este modelo de tela // // //

    carregarDadosFiltrados() {

      const self = this;

      self.loading++;

      this.$api.get('/tickets'  + this.api_get + '&filters=' + self.filtrosQuery).then(function (response) {

        const dados = response.data;

        if(dados.success) {
          self.tickets = dados.data;
        }

        self.loading--;
        
      });

    },

    validaAutoComplete(val) {
      if(typeof this[val] != "object")
        this[val] = null;
    },

    filtrarAutoComplete: function($e) {

      const self = this;

      const q = $e.query.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

      var ids_selecionados = [];
      if(self.filtrosSelecionados != null) {
        self.filtrosSelecionados.forEach((i) => {
          ids_selecionados.push(i.id);
        });
      }

      self.filtrosResultados = [];
      for (const _g of Object.values(self.filtrosOpcoes)) {
        const g = Object.assign({}, _g);
        var el = [];
        for (const _e of Object.values(g.items)) {
          const e = Object.assign({}, _e);
          if(e.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(q) >= 0) {
            if(ids_selecionados.indexOf(e.id) == -1) {
              e.completeLabel = g.label + ': ' + e.label; // Mudança na apresentação do dado selecionado
              el.push(e);
            }
          }
        }
        if(el.length > 0) {
          g.items = el;
          self.filtrosResultados.push(g);
        }
      }

      if(typeof self.filtrosPersonalizados == 'string') {

        self.filtrosResultados.push({
          label: self.filtrosPersonalizados, id: 'str', 
          items: [
            {label: $e.query, id: $e.query, group: 'str', completeLabel: self.filtrosPersonalizados + ': ' + $e.query}
          ]
        });

      }

    },

    processoFiltrarAutoComplete(event) {

      const self = this;

      const q = event.query.toLowerCase();

      this.$api
        .get(
          "/processos?fields=id&relationships=imovel_item:id_proprio;locatario_item:nome&filters=" +
            '{"id":["' +
            q +
            '"]}'
        )
        .then(function (response) {
          const dados = response.data;
          if (dados.success) {

            let processos = [];
            for(const p of dados.data) {
              processos.push({
                "id" : p.id,
                "label" : 'Negócio: ' + p.id + ' (' + p.imovel_item.id_proprio + ' - ' + p.locatario_item.nome + ')'
              });
            }

            self.processoResultados = processos;

          }
        });

    },

    destinatarioFiltrarAutoComplete(event) {

      const self = this;

      const q = event.query.toLowerCase();

      this.$api
        .get("/tickets/destinatarios?str=" + q)
        .then(function (response) {
          const dados = response.data;
          if (dados.success) {

            self.destinatarioResultados = dados.data;

          }
        });

    },

    limparFormulario() {
      //Limpa as variáveis
    
      // 2. Campos do formulário
      this.assunto = "";
      this.mensagem = "";
      this.mensagem_corretora = "";
      this.processo = null;

      for(const a of this.arquivos) {
        this.$api.post("/arquivos/" + a.id, {"_method" : "DELETE"});
      }

      this.arquivos = [];

    },

    upload_headers(event) {
        event.xhr.setRequestHeader("Authorization", "Bearer " + this.$auth.token());
    },

    upload_progress(event) {
        this.progress_upload = event.progress;
    },

    upload_error(event) {
        const res = event.xhr.response;

        self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: res.message,
            life: 5000,
        });

    },

    upload_click(event, file) {

        const self = this;

        this.$confirm.require({
            target: event.currentTarget,
            message: 'O que deseja fazer?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Abrir",
            rejectLabel: "Excluir",
            acceptClass: "p-button-success",
            rejectClass: "p-button-danger",
            accept: () => {
                window.open(self.$api.url() + '/arquivos/' + file.filename_final, '_blank');
            },
            reject: () => {

                self.$api
                    .post(
                    "/arquivos/" + file.id, {"_method" : "DELETE"})
                    .then(function (response) {

                        const idx = self.arquivos.findIndex(e => e.id == file.id);
                        self.arquivos.splice(idx, 1);

                        self.$toast.add({
                            severity: (response.data.success)?"success":"error",
                            summary: (response.data.success)?"Sucesso!":"ERRO!",
                            detail: response.data.message,
                            life: (response.data.success)?3000:5000,
                        });

                    })
                    .catch(function (erro) {

                        if (typeof erro.response != "undefined") {

                            const response = erro.response;

                            self.$toast.add({
                                severity: "error",
                                summary: "ERRO!",
                                detail: response.data.message,
                                life: 5000,
                            });
                        }

                    });

            }
        });

    },

    upload_finalizado(event) {
        const res = JSON.parse(event.xhr.response);
        this.arquivos = this.arquivos.concat(res.data);
    },

    // // // AÇÕES DO USUÁRIO // // //

    abrirProcesso() {
      if(this.processo)
        window.open(this.$router.resolve('/processo/' + this.processo.id).href, '_blank');
    },

    criar() {
      const self = this;

      this.fieldsError = [];

      let dados = {
        "assunto": this.assunto,
        "mensagem": this.mensagem,
        "mensagem_corretora": this.mensagem_corretora,
        "processo": this.processo?this.processo.id:null,
      };

      if(this.destinatario)
        dados[this.destinatario.field] = this.destinatario.id;

      if(this.arquivos.length > 0) {
        dados['arquivos'] = [];
        for(const a of this.arquivos) {
          dados['arquivos'].push(a.id);
        }
      }

      this.$api
        .post(
          "/tickets",
          dados
        )
        .then(function (response) {

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

          self.arquivos = [];

          self.limparFormulario();

          self.carregarDadosFiltrados();

        })
        .catch(function (erro) {

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            if(typeof response.data.fields != "undefined") {

              const dicionario = {             
              };

              for(const f of response.data.fields) {

                if(typeof dicionario[f] != "undefined") {
                  self.fieldsError.push(dicionario[f]);
                } else {
                  self.fieldsError.push(f);
                }

              }

            }

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

        });
    },

    arquivar(event, ticket) {

      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Tem certeza que deseja arquivar?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: () => {

            self.$api
                .post(
                "/tickets/" + ticket, {"_method" : "DELETE"})
                .then(function (response) {

                    self.$toast.add({
                        severity: (response.data.success)?"success":"error",
                        summary: (response.data.success)?"Sucesso!":"ERRO!",
                        detail: response.data.message,
                        life: (response.data.success)?3000:5000,
                    });

                    self.carregarDadosFiltrados();

                })
                .catch(function (erro) {

                    if (typeof erro.response != "undefined") {

                        const response = erro.response;

                        self.$toast.add({
                            severity: "error",
                            summary: "ERRO!",
                            detail: response.data.message,
                            life: 5000,
                        });
                    }

                });

            }
        });

    }

  },
};
</script>

<style scoped>

  :deep(.col-data), :deep(.col-ultima_interacao) {
    width: 165px;
  }

  :deep(.col-id) {
    width: 80px;
  }

  :deep(.col-status) {
    width: 129px;
  }

</style>